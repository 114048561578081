// ------------------------------------------------------------
// Sizing
// ------------------------------------------------------------
@use "sass:math";

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::selection {
  background: $gnr-sand;
}

// 'xl' for Extra large
.type-xxl {
	@include font-size($gamma*1.25);
	@include leading(math.div($alpha, 1.25));
	@include respond-to($c-vp) {
		@include font-size($alpha*1.15);
	}
	&--leader {
		@include leader(math.div($alpha, 1.45));
	}
	&--trailer {
		@include trailer($alpha*0.2);
	}
}


// 'xl' for Extra large
.type-xl {
	@include font-size($gamma);
	@include leading(math.div($alpha, 1.5));
	@include respond-to($c-vp) {
		@include font-size($alpha);
	}
	&--leader {
		@include leader($alpha);
	}
	&--trailer {
		@include trailer($alpha*0.5);
	}
}

// 'l' for Large
.type-l {
	@include font-size($beta);
	&--leader {
		@include leader($beta);
	}
	&--trailer {
		@include trailer($beta*0.5);
	}
}

// 'm' stands for Median not Medium
// This should be the middle typesize
.type-m {
	@include font-size(math.div($gamma, 1.25));
	line-height: 1.3;
	&--leader {
		@include leader($gamma);
	}
	&--trailer {
		@include trailer($gamma*0.5);
	}
}

// 's' for Small
.type-s {
	@include font-size($delta);
	&--leader {
		@include leader($delta);
	}
	&--trailer {
		@include trailer($delta*0.5);
	}
}

// 'xs' for Extra small
.type-xs {
	@include font-size($epsilon);
	&--leader {
		@include leader($epsilon);
	}
	&--trailer {
		@include trailer($epsilon*0.5);
	}
}


// ------------------------------------------------------------
// Headings
// ------------------------------------------------------------

.heading {
	@extend %font-heading;
}

// ------------------------------------------------------------
// Assignments for commonly used text
// ------------------------------------------------------------

// Paragraphs
.para {
	font-size: 1.25rem;
	@include respond-to($c-vp) {
		@include font-size($para);
	}
	&.para--large {
		font-size: 1.45rem;
		@extend .heading;
		@include respond-to($c-vp) {
			@include font-size($gamma);
			line-height: 1.7;
		}
	}
}

.reverse-text {
	color: #fff;
}

.para--typeset 					{ @include trailer($para); }

// List items
.list-item						{ @include font-size($para); }
.list-item--typeset 			{ @include trailer($para*0.5); }

// Lists
.unordered-list 	{
	@include unordered;
	padding-left: 3em;
	li {
		@extend .list-item;
		@extend .list-item--typeset;
	}
}

.ordered-list 	{
	@include ordered;
	padding-left: 3.5em;
	li {
		@extend .list-item;
		@extend .list-item--typeset;
	}
}

.nobullet-list 	{
	@include nobullet;
	li {
		@extend .list-item;
		@extend .list-item--typeset;
	}
}

.list--typeset 					{ @include trailer($para); @include leader($para); }
.unordered-list--typeset 		{ @extend .list--typeset; }
.ordered-list--typeset 			{ @extend .list--typeset; }


// ------------------------------------------------------------
// Use on parent element to style elements within. E.g an article page
// ------------------------------------------------------------

.is-typeset {

	a {
		@extend .heading;
	}

	h1 {
		max-width: 650px;
		@extend .type-xxl;
		@extend .heading;
		@include leader($alpha);
		@include trailer(math.div($alpha, 3));
		line-height: 1.3;
	}

	h2 {
		@extend .type-xl;
		@include leader($alpha);
		@include trailer(math.div($alpha, 3));
		@extend .heading;
		line-height: 1.3;
	}

	h3 {
		@extend .type-l;
		@include leader($alpha);
		@include trailer(math.div($alpha, 3));
		@extend .heading;
		line-height: 1.3;
	}

	h4 {
		@extend .type-m;
		@include leader($alpha);
		@include trailer(math.div($alpha, 3));
		@extend .heading;
	}

	h5, h6 {
		@extend .type-s;
		@include leader($alpha);
		@include trailer(math.div($alpha, 3));
		@extend .heading;
	}

	ol {
		@extend .ordered-list;
		margin-bottom: 3rem;
	}

	ul {
		@extend .unordered-list;
		margin-bottom: 3rem;
	}

	p {
		@extend .para;
		@extend .para--typeset;
	}

	img {
		@extend %max-width;
		margin: math.div($para, $rembase) + em 0;
	}

	a:not(.button) {
		text-decoration: underline;
	}

	// Tear out the initial top margin to allow things to flush together
	> h1:first-child,
	> h2:first-child,
	> h3:first-child,
	> h4:first-child,
	> h5:first-child,
	> h6:first-child,
	> img:first-child,
	> media-video-wrapper:first-child  {
		margin-top: 0;
	}

	// Stop it with your spacing with empty tags madness
	br + br,
	h1:empty,
	h2:empty,
	h3:empty,
	h4:empty,
	h5:empty,
	h6:empty,
	p:empty,
	ul:empty,
	ol:empty,
	span:empty,
	cite:empty,
	div:empty {
		display: none;
	}

	.floatLeft {
		@include respond-to($c-vp) {
			max-width: 45%;
			margin-right: 5%;
			float: left;
			display: inline;
		}
	}
	.floatRight {
		@include respond-to($c-vp) {
			max-width: 45%;
			margin-left: 5%;
			float: right;
			display: inline;
		}
	}
}

// No bottom. Like a reverse Kim Kardashian
.is-typeset--no-trailing {
	p, li, ul, ol {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

// link style
.link-style {
	@extend .heading;
	text-decoration: underline;
	word-wrap: break-word;
}

// imageCaption
.imageCredit {
	position: relative;
	a {
		@extend %font-heading;
		text-decoration: underline;
	}
}

.imageCredit__img {
	display: block;
	max-width: 100%;
}

.imageCredit__credit {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 1.5rem 1.5rem 0 0;
	background: #fff;
}
