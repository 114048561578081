// Referenced in base/libs/_placeholders.scss

// ------------------------------------------------------------
// Local fonts
// ------------------------------------------------------------

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=05f991d2-e8b0-430f-8bae-44f6cf1db4e3&fontids=2999421,2999427");
@font-face{
    font-family:"Averta W01 Regular";
    src:url("../fonts/2999421/0eed2645-6c27-4b16-a7d9-c3aba430b72a.eot?#iefix");
    src:url("../fonts/2999421/0eed2645-6c27-4b16-a7d9-c3aba430b72a.eot?#iefix") format("eot"),
    url("../fonts/2999421/89f1e0ce-0337-46bd-83eb-521970fb47c9.woff2") format("woff2"),
    url("../fonts/2999421/ffec1ad2-fea3-4d14-bc1f-bc1b0396417b.woff") format("woff"),
    url("../fonts/2999421/f80b0811-6190-476c-9727-e900aae82ef3.ttf") format("truetype");
}
@font-face{
    font-family:"Averta W01 Bold";
    src:url("../fonts/2999427/38ab563e-cee7-4b1c-911e-11e659b89d59.eot?#iefix");
    src:url("../fonts/2999427/38ab563e-cee7-4b1c-911e-11e659b89d59.eot?#iefix") format("eot"),
    url("../fonts/2999427/c52b7ee6-2bc8-4dfc-8fef-36af3c2d0f63.woff2") format("woff2"),
    url("../fonts/2999427/51d44388-ec35-4d35-a644-48812f2b3f67.woff") format("woff"),
    url("../fonts/2999427/adefe563-e9f8-48ad-ad25-8fdd3a6d4827.ttf") format("truetype");
}



// ------------------------------------------------------------
// SVN fonts
// ------------------------------------------------------------

// @import url(http://fonts.googleapis.com/css?family=Merriweather:400,700);
