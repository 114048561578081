@media print {

    body.body--tracking {

        print-color-adjust: exact;

        .hero {
            background-color: $gnr-slate;
        }

        .hero,
        .hero__pattern {
            background-image: none !important;
            &:before,
            &:after {
                content: none;
                background-image: none !important;
            }
        }

        .hero__heading,
        .hero__para {
            color: rgba(255, 255, 255, 0);
            text-shadow: 0 0 0 #fff;
            print-color-adjust: exact;
        }

        .meta__update,
        .meta__date {
            color: rgba(255, 255, 255, 0);
            text-shadow: 0 0 0 #fff;
            print-color-adjust: exact;
        }

        a[href]:after {
            content: " (" attr(href) ")";
        }

        .button[href] {
            border: none;
            padding: 0;
            &:after {
                content: " (" attr(href) ")";
                display: block;
                text-transform: none;
            }
        }

        .skiplink,
        *[role="navigation"],
        *[role="footer"],
        header.header,
        footer, {
            display: none !important;
        }

        .hero__caption--center {
            max-width: none;
        }
        .data__tag {
            border-left: 3px solid $gnr-slate;
            padding-left: 5px;
            span {
                border-top: none;
            }
        }
        .data__items {
            overflow: hidden;
            width: 100%;
            .data__item {
                width: 33.33%;
                float: left;
                display: block;
            }
        }
        form, .m-search__heading, .inPageNav {
            display: none;
        }
        .teaser__img {
            display: none;
        }
        .l-2up {
            overflow: hidden;
            .teaser__item {
                float: left;
                width: 47.5%;
                margin-right: 5%;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(2n + 1) {
                    clear: left;
                }
            }
        }
        .teaser__item {
            page-break-inside: avoid;
            background-color: #fff;
            border: 1px solid $gnr-slate;
            .meta__date, .teaser__heading, .teaser__list {
                color: $base-color;
            }
            a[href]:after {
                content: none;
            }
        }
    }
}
