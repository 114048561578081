// ------------------------------------------------------------
// Base styles
// ------------------------------------------------------------

a {
	color: $action;
	text-decoration: none;
	//transition: $transition;
	@include transition(color);
	@include link-active-styles {
		color: $action-respond;
	}
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

.skiplink 	{
	background: #fff;
	color: $action;
	border: $default-border-width solid $default-border-color;
	display: block;
	font-size: 16px;
	line-height: 40px;
	padding: 0 20px;
	position: absolute;
	top: -60px;
	left: 0;
	transition: all ease-in-out 0.1s;
	&:focus,
	&:active {
		top: 0;
		z-index: 80;
	}
}

.pagecontent 	{ outline: none; position: relative; }
.max-width 		{ @extend %max-width; }

// SVG bug work aroud whereby svg's require a height of some kind
svg { max-height: 10000px; }

// General type controllers
// ------------------------------------------------------------

h1,h2,h3,h4,h5,h6 	{ color: $heading-color; }
ul, ol 				{ @include nobullet; }
strong, b 			{ font-weight: 600; }
em, i, q 			{ font-style: italic; }
a 					{ font-size: inherit;}
h1,h2,h3,h4,h5,h6,li,p {
	span, cite, em, i, q, strong, b {
		font-size: inherit;
	}
}
