/* ::Print */
/* ------------------------------------------------------------ */

@use "sass:math";

@media print {

    body {
        counter-reset: page;
        font-size: percentage(math.div(10, 16));
        color: #000!important;
        background-color: #fff!important;
    }

    *[role="navigation"],
    *[role="footer"],
    footer,
    .search,
    .m-profile-filter   {
        display: none!important;
    }

    .hero__heading {
        font-weight: bold;
    }

    // Targets in profiles
    .targets {
        overflow: hidden;
    }

    .targets__item {
        overflow: hidden;
        display: inline-block;
        width: 30%;
        margin-right: 3%;
        vertical-align: top;
    }

    .targets__media {
        width: 30%;
        margin-right: 5%;
        float: left;
    }

    .targets__content {
        width: 65%;
        float: left;
    }

    .l-2up,
    .l-3up,
    .l-34p {
        overflow: hidden;
    }

    .l-2up__col,
    .l-3up__col,
    .l-4up__col {
        float: left;
        margin-left: 5%;
    }

    .l-2up__col {
        width: 47.5%;
        &:nth-child(2n+1) {
            clear: left;
            margin-left: 0;
        }
    }

    .l-2up-offset {
        .l-2up__col {
            width: 56.25%;
            +.l-2up__col {
                width: 43.75%;
                margin-left: 0;
            }
        }
    }

    .l-3up__col {
        width: 30%;
        margin-left: 5%;
        &:nth-child(3n+1) {
            clear: left;
            margin-left: 0;
        }
    }

    .l-4up__col {
        width: 21%;
        margin-left: 5%;
        &:nth-child(4n+1) {
            clear: left;
            margin-left: 0;
        }
    }

    .table-profile {
        margin: 1em 0;
        @include font-size($para);
        border-top: 1px solid $gnr-slate;
        border-bottom: 1px solid $gnr-slate;
        width: 100%;
        thead {
            font-weight: bold;
            border-bottom: 1px solid $gnr-slate;
            width: 100%;
        }
        tbody tr {
            border-bottom: 1px solid $gnr-cloud;
            &:last-child {
                border-bottom: none;
            }
        }
        th {
            padding: 0.75em;
        }
        td {
            padding: 0.75em;
            &.table-profile__error {
                color: $error;
            }
            &.table-profile__success {
                color: $success;
            }
            &.table-profile__unknown,
            &.table-profile__undetermined {
                color: $gnr-concrete;
            }
        }
    }
}
