@media print {

    body.body--profiles {

        print-color-adjust: exact;

        .hero,
        .hero__pattern {
            background-image: none !important;
            &:before,
            &:after {
                background-image: none !important;
            }
        }

        .hero__heading {
            color: #fff !important;
        }

        .section.fill-cloud-light {
            display: none;
        }

        a[href]:after {
            content: " (" attr(href) ")";
        }

        .skiplink,
        *[role="navigation"],
        *[role="footer"],
        header.header,
        footer, {
            display: none !important;
        }

        .hero__caption--center {
            max-width: none;
        }

        .m-intro__heading {
            font-size: 2rem;
        }

        .m-intro__subheading {
            font-size: 1.75rem;
        }

        .m-notes {
            font-size: 1rem;
        }

        .m-intro__media__img {
            max-width: 100%;
        }

        .is-typeset {
            p {
                font-size: 1.25rem;
            }
            &--additionals {
                >p:first-of-type {
                    font-size: 1.4rem;
                }
            }
        }


        .search,
        #profile,
        a.branding,
        .js-profile-nav,
        .m-intro__aside,
        #tota11y-toolbar,
        [data-js="share-section-trigger"],
        [data-js="share-section"],
        .charts--controls {
            display: none !important;
        }

        section.section {
            break-inside: avoid;
            page-break-inside: avoid;
        }

        section.section--overview, {
            break-inside: auto;
            page-break-inside: auto;
            .section--overview--targets {
                break-inside: avoid;
                page-break-inside: avoid;
            }
        }

        section.section--grouped-data {
            break-inside: auto;
            page-break-inside: auto;
            .row.charts--grouped {
                break-inside: avoid;
                page-break-inside: avoid;
            }
        }

        .targets__heading,
        .targets__note {
            font-size: 1rem;
        }

        [data-grouped-charts-container] {
            display: block !important;
        }

        .table-profile {
            th, td {
                font-size: 1rem;
            }
        }

        .l-3up--key {
            grid-template-columns: 1fr 1fr 0.4fr;
            .l-3up__col {
                @supports (display: grid) {
    				width: auto;
    				margin: 0;
    				&:nth-child(2n) {
    					width: auto;
    				}
    				&:nth-child(3n) {
    					width: auto;
    					margin: 0;
    				}
    				&:last-child {
    					width: auto;
    					align-self: none;
    					grid-column-start: 3;
        				grid-row-start: auto;
    				}
    			}
            }

        }

        .l-2-3up {
            grid-template-columns: 2fr 1fr;
        }

        .charts-container__tabs {
            input, label {
                display: none !important;
            }
        }

        .m-block {
            &__item {
                width: 33%;
            }
            &__copy,
            &__status {
                font-size: 1rem;
            }
        }
    }
}
