// ------------------------------------------------------------
// Colours
// ------------------------------------------------------------

// Brand colours
// Filter properties from here: https://codepen.io/sosuke/pen/Pjoqqp
// ------------------------------------------------------------

$gnr-sunset                         : #de5d09;
$gnr-sunrise                        : #f39000;
$gnr-sand                           : #fcc97a;
$gnr-coral                          : #007495;
$gnr-aqua                           : #93cac9;
$gnr-sky                            : #b2d8d7;
$gnr-sky-light                      : #d1e7e5;
$gnr-slate                          : #475c6d;
$gnr-concrete                       : #a0adbb; // filter: brightness(0) saturate(100%) invert(74%) sepia(14%) saturate(291%) hue-rotate(170deg) brightness(90%) contrast(91%);
$gnr-air                            : #b7c3d0;
$gnr-cloud                          : #cfd9e5;
$gnr-slate-grey                     : #4a4a4a;

// A11y colour variations
// ------------------------------------------------------------

$gnr-sunset-a11y                    : #C45308;
$gnr-slate-a11y                     : #2E3C48;
$gnr-coral-a11y                     : #0A5F78;

// Social
// ------------------------------------------------------------

$facebook-color 					: #3B5998;
$twitter-color 						: #4099FF;
$linkedin-color						: #007bb6;
$youtube-color						: #e52d27;
$pinterest-color					: #C92228;
$instagram-color 					: #517fa4;
$tumblr-color 						: #32506d;
$foursquare-color 					: #0072b1;
$google-color 						: #dd4b39;
$flickr-color 						: #ff0084;


// Colors assigned to roles
// Replace assignments with brand colours
// ------------------------------------------------------------

// Defaults
$body-color 						: #fff;
$base-color							: $gnr-slate;
$heading-color						: $gnr-slate;

// Actions
$action								: $gnr-sunset-a11y;
$action-respond						: $gnr-coral;

// Borders
$default-border-color 				: $gnr-sky;
$default-border-color               : $gnr-concrete;

// Forms
$form-label-color					: $heading-color;
$form-legend-color					: $heading-color;
$form-placeholder-color				: #ddd;
$form-checkbox-color				: $heading-color;
$form-radio-color					: $heading-color;

// Navigation
$nav-toggle-color					: #fff;
$nav-toggle-color-active			: $action;


// Responses
// Often good to keep these 'off–brand' for UX clairty
$warning 							: #81750E;					// Careful now
$error 								: #CB410B;					// Down with that sort of thing
$success 							: #2A745E;					// Good bunch of lads
