@use "sass:math";

@import "normalize.scss";

@import "core/reset";
@import "core/settings";
@import "core/mixins";
@import "core/placeholders";

/* ------------------------------------------------------------ */

html { font-size: 10px; }

body {
	@extend %font-body;
	font-size: $base-font-size + px;
	line-height: math.div($base-rhythm, $base-font-size);
	background: $body-color;
	color: $base-color;
}

.ui-base {
	//width: $ie-fixed-vp;
	margin-left: auto;
	margin-right: auto;
}

/* :: Basic styling for coherent, simple content */
/* ------------------------------------------------------------ */

.row {
	@extend %pie-clearfix;
	min-width: auto;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: $max-grid;
	padding-left: $internal-spacing*0.25;
	padding-right: $internal-spacing*0.25;
	@include respond-to($menu-respond) {
		padding-left: $internal-spacing;
		padding-right: $internal-spacing;
	}
}

.row__nojs {
	html.no-js & {
		@extend %pie-clearfix;
		min-width: auto;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: $max-grid;
		padding-left: $internal-spacing*0.25;
		padding-right: $internal-spacing*0.25;
		@include respond-to($menu-respond) {
			padding-left: $internal-spacing;
			padding-right: $internal-spacing;
		}
	}
}

.row-smler {
	max-width: 1000px;
	margin: 0 auto;
}

// Basic type styles for browsers that don't understand REM
h1 		{ font-size: ($alpha + px); margin: ($base-rhythm*0.5 +px) auto; }
h2 		{ font-size: ($beta + px); margin: ($base-rhythm*0.5 +px) auto;}
h3, h4 	{ font-size: ($gamma + px); margin: ($base-rhythm*0.5 +px) auto;}
h5, h6 	{ font-size: ($delta + px); margin: ($base-rhythm*0.5 +px) auto;}
p,li 	{ font-size: ($para + px); margin: ($para) auto;}
ul, ol 	{ margin: ($base-rhythm*0.5 +px) auto; }
img 	{ margin: ($base-rhythm*0.5 +px) auto; max-width: $ie-fixed-vp; }

/* :: Get core styling for basic layout */
/* ------------------------------------------------------------ */

@import "core/base";
@import "core/forms";
@import "core/fonts";
@import "core/typography";


.button {
	background-color: $action;
	color: #fff;
	padding: 0.25em 0.5em;
}

.navigation-primary-toggle,
.navigation-utility__item,
.search-toggle,
.filterBlock__title,
.countries-search,
.countries-menu__link {
	display: none;
}

.hero {
	background-size: 0;
}

.meta__location {
	img {
		display: none;
	}
}

.shareBlock__item {
	img {
		max-width: 100px;
    height: 100%; // Bug fix for IE11
	}
}

.search {
    input {
    	width: 220px;
    }
}

// Import print modules
@import "print/_print.scss";
@import "print/_profiles.scss";
@import "print/_tracking.scss";
