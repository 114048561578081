// ------------------------------------------------------------
// Settings
// ------------------------------------------------------------

@import "color";

// border
$border-width: 5px;

// ------------------------------------------------------------
// :: Spacing units
// ------------------------------------------------------------

$internal-spacing-percent 			: 6%;		// Space internally with flexible measure.
$internal-spacing					: 6em; 		// Space internally with fixed measure. Renders in EM
$internal-spacing-pixel				: 60px; 	// Space internally with fixed measure. Renders in pixels


$internal-spacing-sml 				: 3rem;

// ------------------------------------------------------------
// :: Breakpoints
// ------------------------------------------------------------

// Screens (as needed)
// --------------------------
$a-vp								: 400px;
$b-vp 								: 500px;
$c-vp 								: 780px;
$d-vp 								: 1000px;
$e-vp 								: 1350px;
$f-vp 								: 2000px;


// Grid assignments
// --------------------------
$max-grid							: $e-vp;								// Max grid
$max-vp								: $e-vp + $internal-spacing-pixel*2;	// Max viewport


// Set assignments
// --------------------------
$table-respond						: $c-vp;								// Responsive tables
$menu-respond						: 960px;								// If using ss/non ss navigation
$sidebar-respond					: $d-vp;								// Sidebar switch point
$sidebar-aside-width				: 20%;									// Sidebar column default
$sidebar-body-width					: 80%;								// Sidebar body default


// FFS assignments
// --------------------------
$ie-fixed-vp						: $d-vp;    							// Viewport for Old, Old Wooden Ships
$device-safezone-vp					: 800px;								// IOS (maybe other places) have some peculiar issues



// ------------------------------------------------------------
// :: Set variables
// ------------------------------------------------------------

$default-border-width 				: 1px; 		// Border width
$border-radius						: 10px;		// Default radius
$form-item-height					: 44px;		// Form item height
$form-padding						: 20px;		// Padd forms with fixed measure


// ------------------------------------------------------------
// :: Type (Renders in REM using @include font-size($#);
// ------------------------------------------------------------

$alpha-papa : 55;
$alpha   	: 32;
$alpha-md   : 45;
$beta		: 26;
$gamma		: 22;
$delta		: 16;
$epsilon	: 14;
$zeta		: 14;
$eta		: 11;

// Set units
// --------------------------------

$base-font-size		: $epsilon;     // Page default size
$para				: $epsilon;  	// Paragraph size
$quote				: $gamma;		// Quote size
$form-label-size    : $epsilon;		// Form label size
$form-legend-size	: $delta;		// Form legend size


// Calc units
// --------------------------------
$rembase 			: 10;			// What do we want our units to calculate from. 10 is nice and easy
$base-rhythm		: 24;			// Vertical rhythm


// transitions
// --------------------------------
$transition: all 200ms cubic-bezier(0.550, 0.085, 0.680, 0.530);
$transition-fast: all 100ms cubic-bezier(0.550, 0.085, 0.680, 0.530);
$transition-slow: all 350ms cubic-bezier(0.550, 0.085, 0.680, 0.530);
// for transition mixin
$transition-base: 200ms cubic-bezier(0.550, 0.085, 0.680, 0.530);
