// Form defaults
// Over-ride/delete as needed
// ------------------------------------------------------------

label, legend, select {
	display: block;
	color: $form-label-color;
}

input[type="search"]::search-decoration,
input[type="search"]::search-cancel-button,
input[type="submit"],
button,
.button {
	appearance: none;
	border-radius: 0;
}

button,
.button {
	border: none;
	background-color: transparent;
	text-align: left;
}

select, input, textarea, button, .button {
	@extend %font-body;
	border-radius: 0;
}

input,textarea {
	&::-webkit-input-placeholder 		{ color: $form-placeholder-color; }
	&:-moz-placeholder 					{ color: $form-placeholder-color; }
	&::-moz-placeholder 				{ color: $form-placeholder-color; }
	&:-ms-input-placeholder 			{ color: $form-placeholder-color; }
}

textarea {
	overflow: auto;
	vertical-align: top;
	font-family: inherit;
}

legend {
	@include font-size($form-legend-size);
}

label {
	@include font-size($form-label-size);
}

fieldset {
	margin: 0;
	display: block;
	width: 100%;
	clear: both;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="number"],
textarea {
	width: 100%;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	*margin-top: 0;
	line-height: normal;
	cursor: pointer;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"],
input[type="submit"]
button {
	width: auto;
}

select,
input[type="file"] {
	*margin-top: 4px; /* In IE7, the height of the select element cannot be changed by height, only font-size */
}

select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	//outline: thin dotted #333;
	//outline: 5px auto focus-ring-color;
	//outline-offset: -2px;
}